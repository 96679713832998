import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/400-italic.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import './slider.css';
import {
  type ChakraTheme,
  extendTheme,
  withDefaultColorScheme,
} from '@chakra-ui/react';
import colors from './colors';
import components from './components';
import layerStyles from './layerStyles';
import semanticTokens from './semanticTokens';
import space from './space';
import textStyles from './textStyles';

const styles: ChakraTheme['styles'] = {
  global: ({ colorMode }) => ({
    'html, body': {
      color: 'var(--chakra-colors-neutral-1-base)',
      caretColor: colorMode === 'dark' ? colors.blue[200] : colors.blue[500], // caret-color isn’t a Chakra style prop so the color accessor doesn’t work

      backgroundColor: 'neutral.bg.0',
      ...textStyles.duck,
    },
    html: { height: '100%', '-webkit-tap-highlight-color': 'transparent' },
    body: { minHeight: '100%' },

    button: {
      WebkitTapHighlightColor: 'transparent', // Remove blue outline when tapping on Android
    },

    input: {
      // Override iOS Safari date input defaults
      '&::-webkit-date-and-time-value': {
        textAlign: 'left',
      },

      '&[type="date"]': {
        backgroundColor: 'transparent',
      },
      //

      // Override Chrome defaults
      '&::-webkit-calendar-picker-indicator': {
        margin: 0,
      },
    },

    'header, h1, h2, h3, h4, h5, h6': {
      ...textStyles.popSwan,
    },

    h1: {
      color: 'brand.2',
    },

    '.chakra-modal__content-container .chakra-modal__content': {
      backgroundColor: 'neutral.bg.0',
    },

    '.chakra-modal__header h2': {
      ...textStyles.popFlamingo,
    },

    label: {
      ...textStyles.popDuck,
    },

    '.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar': {
      display: 'none',
    },

    '.react-horizontal-scrolling-menu--scroll-container': {
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none' /* Firefox */,
    },
  }),
};

export default extendTheme(
  withDefaultColorScheme({
    colorScheme: 'blue',
  }),
  {
    colors,
    semanticTokens,
    shadows: {
      base: `0 0 2px 1px ${colors.gray[100]}`,
    },
    config: {
      initialColorMode: 'light',
      // useSystemColorMode: true,
    },
    fonts: {
      body: 'Open Sans, Helvetica, Arial, sans-serif',
      heading: 'Poppins, Helvetica, Arial, sans-serif',
    },
    textStyles,
    layerStyles,
    space,
    components,
    styles,
  },
);

export { colors, textStyles, space };
