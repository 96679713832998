import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  Image,
  Link,
  Spacer,
  Stack,
  StackDivider,
  chakra,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import logoImage from '@src/assets/logo.svg';
import logomarkImage from '@src/assets/logomark.svg';
import AppStoreLinks from '@src/components/AppStoreLinks';
import ShareButton from '@src/components/ShareButton';
import { websiteUrl } from '@src/constants';
import { useLandingStoreUrls } from '@src/hooks';
import { ChevronLeft } from '@src/icons';
import { determineMobileOperatingSystem } from '@src/utils';
import type { FC } from 'react';
import { NavLink, Link as RouterLink } from 'react-router-dom';

const ChakraNavLink = chakra(NavLink);

const links = [
  { to: '/learn', text: 'Learn' },
  { to: '/for-business', text: 'For business' },
  { to: '/about', text: 'About' },
  { to: '/sign-in', text: 'Sign in' },
];

const mobileOperatingSystem = determineMobileOperatingSystem();

const NavBarNoAuth: FC<{
  logoType?: 'logo' | 'logomark';
  ctaType?: 'getStarted' | 'share' | 'contact';
}> = ({ logoType = 'logomark', ctaType }) => {
  const landingStoreUrls = useLandingStoreUrls(mobileOperatingSystem);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const getStartedUrlMapping = {
    ios: landingStoreUrls.appStoreUrl,
    android: landingStoreUrls.playStoreUrl,
    other: '/sign-in?getStarted=true',
  };
  const ctaButton =
    ctaType === 'getStarted' ? (
      <Button
        as={RouterLink}
        to={getStartedUrlMapping[mobileOperatingSystem]}
        variant="gradient"
        color="white"
        textStyle="popPuffin"
        size="sm"
      >
        Get started
      </Button>
    ) : ctaType === 'contact' ? (
      <Button
        as="a"
        variant="gradient"
        color="white"
        textStyle="popPuffin"
        size="sm"
        href="#contact"
      >
        Request a demo
      </Button>
    ) : ctaType === 'share' ? (
      <ShareButton link={websiteUrl} />
    ) : null;

  const actions = useBreakpointValue(
    {
      base: (
        <>
          {ctaButton}
          <IconButton
            aria-label="Open menu"
            icon={<HamburgerIcon width="22px" height="22px" />}
            onClick={onOpen}
            color="gray.400"
            variant="link"
            minWidth="auto"
            p="deci"
          />
        </>
      ),
      md: (
        <>
          {links.map(({ to, text }) => (
            <ChakraNavLink key={to} to={to} textStyle="popPuffin">
              {({ isActive }) => (
                <chakra.span
                  fontWeight={isActive ? 700 : 500}
                  color={
                    isActive ? 'action.primary.active' : 'action.primary.base'
                  }
                >
                  {text}
                </chakra.span>
              )}
            </ChakraNavLink>
          ))}
          {ctaButton}
        </>
      ),
    },
    { ssr: false },
  );

  return (
    <Flex
      as="nav"
      position="sticky"
      top={0}
      backgroundColor="neutral.bg.0"
      alignItems="center"
      justifyContent="space-between"
      pl={{ base: 'regular', md: 'mega' }}
      pr={{ md: 'mega' }}
      gap={{ base: 'milli', md: 'hecto' }}
      mt={{ base: 'deci', md: 'deka' }}
      zIndex={1}
    >
      <Link as={RouterLink} to="/" py="deci" display="inline-block">
        <Image
          src={logoType === 'logo' ? logoImage : logomarkImage}
          height={{ base: '28px', md: '30px' }}
          alt="Unstuck home"
        />
      </Link>
      <Spacer />
      {actions}

      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="full">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100svh"
            width="100%"
            position="absolute"
            p="regular"
          >
            <Box>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                mb="deka"
              >
                <Image
                  src={logoImage}
                  height={{ base: '28px', md: '30px' }}
                  alt="Unstuck home"
                />
                <DrawerCloseButton position="static" />
              </Flex>
              <Stack
                direction="column"
                divider={<StackDivider borderColor="gray.100" />}
                borderY="1px"
                borderColor="gray.100"
                spacing="deci"
                py="deci"
              >
                {links.map(({ to, text }) => (
                  <Link
                    key={to}
                    as={RouterLink}
                    to={to}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    textStyle="popPuffin"
                    px="deci"
                    color="gray.900"
                  >
                    <Box as="span">{text}</Box>
                    <ChevronLeft
                      boxSize="13px"
                      transform="rotate(180deg)"
                      color="blue.500"
                      aria-hidden
                    />
                  </Link>
                ))}
              </Stack>
            </Box>
            <Box>
              <AppStoreLinks variant="outline" showBrowserLink />
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default NavBarNoAuth;
