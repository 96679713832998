import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import {
  FormControl,
  FormErrorMessage,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
} from '@chakra-ui/react';
import { PinStore } from '@src/stores';
import { digestMessage } from '@src/utils';
import { type FC, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as identityService from '../../identityService';

export const useIsPinModalOpen = () => {
  const storedHashedPin = PinStore.useState((s) => s.hashedPin);
  const isVerified = PinStore.useState((s) => s.isVerified);
  const tokens = identityService.useTokens();

  const isOpen = !!tokens && !!storedHashedPin && !isVerified;

  return { isPinModalOpen: isOpen };
};

const PinModal: FC = () => {
  const pinInputRef = useRef<HTMLInputElement>(null);
  const { control, reset, resetField, setError, watch, formState } = useForm({
    defaultValues: { pin: '' },
  });
  const pin = watch('pin');

  const storedHashedPin = PinStore.useState((s) => s.hashedPin);

  const { isPinModalOpen } = useIsPinModalOpen();

  useEffect(() => {
    (async () => {
      if (storedHashedPin && pin.length >= 4) {
        const hashedPin = await digestMessage(pin);
        if (hashedPin !== storedHashedPin) {
          resetField('pin');
          setError('pin', {
            type: 'manual',
            message: 'PIN was incorrect. Please try again.',
          });
          pinInputRef.current?.focus();
          return;
        }

        PinStore.update((s) => {
          s.isVerified = true;
        });
      }
    })();
  }, [storedHashedPin, pin, resetField, setError]);

  useEffect(() => {
    reset();

    // initialFocusRef does not work all of the time.
    if (isPinModalOpen) {
      if (Capacitor.getPlatform() === 'android') {
        Keyboard.show();
      }
      setTimeout(() => {
        pinInputRef.current?.focus();
      }, 50);
    }
  }, [isPinModalOpen, reset]);

  return (
    <Modal
      isOpen={isPinModalOpen}
      onClose={() => {}}
      size={{ base: 'full' }}
      motionPreset="none"
      initialFocusRef={pinInputRef}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Enter your PIN</ModalHeader>
        <ModalBody>
          <FormControl isInvalid={!!formState.errors.pin}>
            <Controller
              name="pin"
              control={control}
              render={({ field }) => (
                <HStack justifyContent="center">
                  <PinInput {...field} mask size="lg">
                    <PinInputField ref={pinInputRef} />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
              )}
            />
            <FormErrorMessage mt="deci" justifyContent="center">
              {formState.errors.pin?.message}
            </FormErrorMessage>
          </FormControl>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PinModal;
