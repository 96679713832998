import { Box, Button, Heading, Text } from '@chakra-ui/react';
import Page from '@src/components/Page';
import { signInEmailStorageKey } from '@src/constants';
import { signInEmailComplete } from '@src/identityService';
import * as storage from '@src/storage';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import { type FC, lazy, useEffect, useRef, useState } from 'react';
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import NativeOpenInApp from './NativeOpenInApp';
const EmailForm = lazy(() => import('./EmailForm'));

const CompleteEmailSignIn: FC<{ isNative?: boolean }> = ({ isNative }) => {
  const queryClient = useQueryClient();
  const didSignIn = useRef(false);
  const [isReady, setIsReady] = useState(false);
  const [errorJson, setErrorJson] = useState<
    undefined | Record<string, unknown>
  >();
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const handleSignInMutation = useMutation({
    mutationFn: ({ oobCode, email }: { oobCode: string; email: string }) =>
      signInEmailComplete(email, oobCode),
    onSuccess: () => {
      storage.remove(signInEmailStorageKey);
      queryClient.removeQueries(); // Must be remove, not reset, otherwise it will fill in the old cached value
      setShouldNavigate(true);
    },
  });

  const oobCode = searchParams.get('oobCode');

  // TODO:
  // biome-ignore lint/correctness/useExhaustiveDependencies(navigate): get it working. Don’t want to investigate this now.
  useEffect(() => {
    if (didSignIn.current === false) {
      didSignIn.current = true;
      if (!oobCode) {
        return setShouldNavigate(true);
      }

      (async () => {
        const storedEmail = await storage.get<string>(signInEmailStorageKey);

        if (storedEmail) {
          await handleSignInMutation.mutateAsync({
            oobCode,
            email: storedEmail,
          });
          return;
        }

        setIsReady(true);
      })();
    }
  }, [navigate, oobCode, handleSignInMutation]);

  useEffect(() => {
    // There’s a chance the navigate function gets still if it’s nested in an async function inside an effect that’s taking a while.
    // Pulling it out here solves that potential issue.
    if (shouldNavigate) {
      navigate('/');
    }
  }, [navigate, shouldNavigate]);

  useEffect(() => {
    (async () => {
      if (!handleSignInMutation.isError) {
        setErrorJson(undefined);
        return;
      }

      const error = handleSignInMutation.error;
      if (error instanceof HTTPError) {
        const json = await error.response.json();
        setErrorJson(json);
        return;
      }
    })();
  }, [handleSignInMutation.isError, handleSignInMutation.error]);

  if (handleSignInMutation.isError) {
    const isOobCodeError =
      typeof errorJson?.errorCode === 'string' &&
      ['EXPIRED_OOB_CODE', 'INVALID_OOB_CODE'].includes(errorJson.errorCode);

    return (
      <Page
        disableTopNavBar
        content={
          <Box>
            <Heading textStyle="popHeron">
              {isOobCodeError ? 'Email link expired' : 'Sign in error'}
            </Heading>
            <Text my="regular">
              {isOobCodeError
                ? 'The verification link we emailed you has expired.'
                : 'Sorry, but an unknown error has occured during the sign in process.'}
            </Text>
            <Box textAlign="center" mt="regular">
              <Button as={RouterLink} to="/sign-in" width="100%" size="lg">
                Try again
              </Button>
              <Button
                as={RouterLink}
                to="/"
                width="100%"
                variant="link"
                mt="deci"
                size="lg"
              >
                Back to home
              </Button>
            </Box>
          </Box>
        }
      />
    );
  }

  if (!isReady) return <Box />;

  return (
    <Page
      disableTopNavBar
      content={
        isNative ? (
          <NativeOpenInApp />
        ) : (
          <EmailForm
            onSubmit={async (email) => {
              if (oobCode) {
                await handleSignInMutation.mutateAsync({
                  oobCode,
                  email: email,
                });
              }
            }}
          />
        )
      }
    />
  );
};

export default CompleteEmailSignIn;
