import { Share } from '@capacitor/share';
import { Button, type ButtonProps, Icon, useToast } from '@chakra-ui/react';
import ShareIcon from '@src/assets/share.svg?react';
import { useCanShare } from '@src/hooks';
import { determineMobileOperatingSystem } from '@src/utils';
import type { FC } from 'react';

const mobileOperatingSystem = determineMobileOperatingSystem();

const ShareButton: FC<
  ButtonProps & {
    link: string;
  }
> = ({ link, ...props }) => {
  const canShare = useCanShare();
  const toast = useToast();

  return (
    <Button
      color="action.tertiary"
      leftIcon={<Icon as={ShareIcon} boxSize="16px" />}
      variant="link"
      textStyle="popPuffin"
      onClick={async () => {
        if (canShare && mobileOperatingSystem !== 'other') {
          try {
            await Share.share({
              url: link,
            });
          } catch (e) {
            // most likely a user cancel, which also throws
          }
          return;
        }

        try {
          toast({
            title: 'Link copied to the clipboard.',
            status: 'success',
            duration: 3000,
            position: 'top',
          });
          await navigator.clipboard.writeText(link);
        } catch (e) {
          // TODO: handle
        }
      }}
      {...props}
    >
      share
    </Button>
  );
};

export default ShareButton;
