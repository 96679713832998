import {
  Box,
  Flex,
  type FlexProps,
  Heading,
  type HeadingProps,
  Image,
} from '@chakra-ui/react';
import type { FC, ReactElement } from 'react';

const ImageWithText: FC<
  {
    headingText: string;
    headingProps?: HeadingProps;
    description: ReactElement | string;
    additionalContent?: ReactElement;
    image: { src: string; alt: string };
    textBoxProps?: FlexProps;
    isImageFirst?: boolean;
  } & FlexProps
> = ({
  headingText,
  headingProps = {},
  description,
  additionalContent,
  textBoxProps,
  image,
  isImageFirst,
  ...props
}) => {
  return (
    <Flex
      flexDirection={{
        base: 'column',
        md: isImageFirst ? 'row-reverse' : 'row',
      }}
      alignItems="center"
      gap={{ base: 'hecto', lg: 'tera' }}
      textAlign={{ base: 'center', md: 'left' }}
      {...props}
    >
      <Box flex={1} {...textBoxProps}>
        <Heading
          color="brand.2"
          textStyle={{ base: 'popEmu', lg: 'popOstrich' }}
          mb="centi"
          {...headingProps}
        >
          {headingText}
        </Heading>
        <Box textStyle={{ base: 'goose', lg: 'swan' }} mt="regular">
          {description}
        </Box>
        {additionalContent && (
          <Box mt="hecto" textStyle="popDuck">
            {additionalContent}
          </Box>
        )}
      </Box>
      <Box flex={1}>
        <Image
          src={image.src}
          alt={image.alt}
          display="inline-block"
          maxHeight={{ base: '26rem', md: '37.5rem' }}
        />
      </Box>
    </Flex>
  );
};

export default ImageWithText;
